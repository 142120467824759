<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="10" tabindex="0">
    <app-header></app-header>
    <main>

        <!-- whatsapp floating button -->
        <app-whatsapp></app-whatsapp>
        <!-- END whatsapp floating button -->

        <!--HERO Faculty-->
        <section class="pb-md-5 position-relative">
            <div class="position-relative faculty-hero d-flex">
                <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
                    <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
                    <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
                </div>
                <div
                    class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
                    <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
                    <div
                        class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
                        <h2 class="heading-font d-inline-block pt-5">
                            <label class="d-block text-sm-start">1957</label>
                            المعهد العالي للسينما
                        </h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb animate__animated animate__fadeInDown">
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">أكادميه الفنون</a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        المعهد العالي للسينما
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    دراسة البكالوريوس
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <div class="container-fluid floated-contatiner-top position-relative">
            <div class="row">
                <div class="col-lg-11 offset-lg-1 bg-white">
                    <div class="col-lg-11 ps-3">
                        <section class="pt-4 mt-3">
                            <h3
                                class="text-center heading-font heading-style mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                الدراسات العليا
                            </h3>
                            <p class="fs-5 fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                درجات علمية لقادة المستقبل. تبحث لتوسيع معرفتك؟ إذن لماذا لا تضيف درجة الماجستير في
                                الفنون إلى درجة البكالوريوس الخاصة بك؟ درجة الماجستير ليست مفيدة فقط للمهنة الأكاديمية ،
                                إنها تفتح الأبواب في مجال الأعمال أيضًا.
                            </p>
                        </section>
                        <section class="py-5 px-2">
                            <div class="row">
                                <div class="col-xl-10 mx-auto degree-list">
                                    <h2
                                        class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                        درجات الدراسات العليا التي يمنحها المعهد
                                    </h2>
                                    <div class="row">
                                        <div class="degree-img col-auto px-0">
                                            <div class="shadow">
                                                <img src="assets/img/diplom-thumb.jpg">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="bg-white px-3 py-md-4">
                                                <h4 class="mb-md-3">دبلوم الدراسات العليا</h4>
                                                <p class="opacity-75 fs-6 mb-0">
                                                    دبلوم عال يؤهل للتقدم لدراسة الماجستير، وهو يلي مرحلة البكالوريوس في
                                                    فنون السينما
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5 mt-md-0">
                                        <div class="degree-img col-auto px-0 offset-md-1">
                                            <div class="shadow">
                                                <img src="assets/img/master-thumb.jpg">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="bg-white px-3 py-md-4">
                                                <h4 class="mb-md-3">ماجستير في فنون السينما</h4>
                                                <p class="opacity-75 fs-6 mb-0">
                                                    درجة مدتها سنتان في فن وحرفة صناعة الأفلام مع التركيز على الجانب
                                                    العملي في جميع أساسيات الإنتاج السينمائي والتلفزيوني
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5 mt-md-0">
                                        <div class="degree-img col-auto px-0 offset-md-2">
                                            <div class="shadow">
                                                <img src="assets/img/PHD-thumb.jpg">
                                            </div>
                                        </div>
                                        <div class="col ">
                                            <div class="bg-white px-3 py-md-4">
                                                <h4 class="mb-md-3">دكتوراة الفلسفة في فنون السينما</h4>
                                                <p class="opacity-75 fs-6 mb-0">
                                                    درجة مدتها سنتان في فن وحرفة صناعة الأفلام مع التركيز على الجانب
                                                    العملي في جميع أساسيات الإنتاج السينمائي والتلفزيوني
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                كل ما يجب أن تعرفه عن درجات الدراسات العليا
                            </h2>
                            <!--brife grid-->
                            <div class="row">
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__faster wow">
                                    <div
                                        class="brief bg-secondary position-relative p-4 overflow-hidden text-white h-100">
                                        <img class="position-absolute fit-img" src="assets/img/register-conditions.jpg">
                                        <div
                                            class="position-absolute w-100 h-100 bg-secondary opacity-75 top-0 start-0">
                                        </div>
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                القبول والتسجيل
                                            </h3>
                                            <p class="mb-0">
                                                قدم عبر الإنترنت و تعرف على شروط و تفاصيل التقديم و مواعيد الاختبارات و
                                                شروطها و التقديم لها
                                            </p>
                                            <a class="btn text-white align-items-center all d-inline-flex ps-1 mt-2">
                                                <span class="fw-bold fs-6">
                                                    إقراء المزيد
                                                </span>
                                                <i class="icon-arrow-sm"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__fast wow">
                                    <div class="brief bg-primary p-4 text-white h-100">
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                التكلفة والمصروفات
                                            </h3>
                                            <p class="mb-0">
                                                تعرف على المواد التي يتم دراستها تكلفه الدراسة
                                            </p>
                                            <div class="row mt-3">
                                                <div class="col-md px-0 border border-primary bg-light">
                                                    <div class="floating-label-select position-relative">
                                                        <select
                                                            class="floating-select form-select border-0 fw-medium bg-light"
                                                            onclick="this.setAttribute('value', this.value);" value="">
                                                            <option value=""></option>
                                                            <option value="1">السيناريو</option>
                                                            <option value="2"> المونتاج</option>
                                                        </select>
                                                        <label>إختر القسم</label>
                                                    </div>
                                                </div>
                                                <div class="col-md px-0 border border-primary bg-light">
                                                    <div class="floating-label-select position-relative">
                                                        <select
                                                            class="floating-select form-select border-0 fw-medium bg-light"
                                                            onclick="this.setAttribute('value', this.value);" value="">
                                                            <option value=""></option>
                                                            <option value="1"> دبلوم الدراسات العليا</option>
                                                            <option value="2">ماجستير في فنون السينما</option>
                                                            <option value="3">دكتوراة الفلسفة في فنون السينما</option>
                                                        </select>
                                                        <label>إختر الدرجة</label>
                                                    </div>
                                                </div>

                                                <div
                                                    class="col-md-auto bg-white px-0 border border-primary border-start-0">
                                                    <button
                                                        class="btn btn-link search-btn position-relative h-100 d-flex align-items-center justify-content-center">
                                                        <i class="icon-search position-relative"></i>
                                                        <span class="d-inline-block d-md-none fs-4 fw-medium py-2 pe-2">
                                                            بحث
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--END brife grid-->
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-1 fw-normal text-center animate__animated animate__fadeInUp animate__faster wow">
                                الدراسات العليا ليست المناسب لك؟
                            </h2>
                            <p
                                class="mb-4 fs-5 opacity-75 text-center fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                برنامج الدراسات العليا ليست مناسب لمهاراتك يمكنك اللإطلاع على البرامج الأخرى
                            </p>
                            <div class="row">
                                <div class="col-lg-10 mx-auto">
                                    <div class="row">
                                        <div
                                            class="col-md-6 mb-md-0 mb-5 animate__animated animate__fadeInUp animate__fast wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img class="w-100" src="assets/img/ba.jpg">
                                                <div
                                                    class="position-absolute bottom-0 end-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    دراﺳﺎت اﻟﺒﻜﺎﻟﻮرﻳﻮس
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6 animate__animated animate__fadeInUp wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img src="assets/img/freelance.jpg">
                                                <div
                                                    class="position-absolute bottom-0 end-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    اﻟﺪراﺳﺎت اﻟﺤﺮة
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
            <div class="row">
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الطالب
                            <p class="mb-0 text-gray mt-1">
                                تعرف على المواد و المهام و النتيجة
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع هيئة التدريس
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الموظفين
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            خدمات الخريجين
                            <p class="mb-0 text-gray mt-1">
                                تعرف على الخدمات الخاصه بالخرجين
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
            </div>
        </section>

    </main>

</div>