<header class="container-fluid sticky-top bg-white home-header nimate__animated animate__fadeIn wow">
  <div class="row main-nav-container">
    <div class="col d-flex">
      <a routerLink="/" class="d-inline-block bg-white academy-header-logo">
        <img src="assets/img/logo.jpg" class="d-block" />
      </a>
    </div>
    <div class="col-auto align-items-center d-flex">
      <div data-bs-toggle="offcanvas" href="#offcanvasNav" role="button" aria-controls="offcanvasNav">
        <a class="btn nav-button bg-white text-black p-3 d-block d-flex">
          <i class="icon-nav lh-1 d-block fs-4"></i>
          <span class="ms-2 fw-medium d-none d-md-inline-block">اكتشف أكادميه الفنون</span>
        </a>
      </div>
      <a class="btn btn-primary fs-5 ms-1 register d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#conditionsModel">
        سجل الان 
      </a>
    </div>
  </div>
</header>