<app-header></app-header>
<main>
  <!-- whatsapp floating button -->
  <div class="whatsapp position-fixed">
    <div class="rounded-circle bg-white shadow align-items-center justify-content-center d-flex">
      <img src="assets/img/whatsApp.svg">
    </div>
  </div>
  <!-- END whatsapp floating button -->

  <!--HERO Faculty-->
  <section class="pb-md-5 position-relative">
    <div class="position-relative faculty-hero d-flex">
      <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
        <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
        <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
      </div>
      <div class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
        <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
        <div class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
          <h2 class="heading-font d-inline-block pt-5">
            <label class="d-block text-sm-start">1957</label>
            المعهد العالي للسينما
          </h2>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb animate__animated animate__fadeInDown">
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">أكادميه الفنون</a>
              </li>
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">
                  المعهد العالي للسينما
                </a>
              </li>
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">
                  الدراسات الحرة
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                تطور فكر فلسفي
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid floated-contatiner-top position-relative course-details">
    <div class="row">
      <div class="col-lg-11 offset-lg-1 bg-white">
        <div class="col-lg-11 ps-3">
          <div class="pt-4 mt-3 pb-5 row">
            <div class="col-xl-6 col-lg-7 mx-auto">
              <div class="pb-5 text-center">
                <img src="assets/img/Confirm.svg" class="mb-5 animate__animated animate__fadeInUp animate__faster">
                <h3 class="heading-font fs-2 mb-2 fw-normal animate__animated animate__fadeInUp animate__fast wow">
                  تم التسجيل في دورة ﺗﻄﻮر ﻓﻜﺮ ﻓﻠﺴﻔﻲ بنجاح
                </h3>
                <p class="fw-medium opacity-75 animate__animated animate__fadeInUp wow mb-4">
                  سوف يتم التواصل عبر البريد الإلكتروني لمراجعة حالة الطلبات
                </p>
                <a
                  class="btn btn-link fs-5 all d-inline-flex align-items-center px-3 py-2 fw-medium border border-primary text-start">
                  تصفح المزيد من الدورات
                  <i class="icon-arrow-sm"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>