<app-app-nav></app-app-nav>
<main>
  <!--HERO Faculty-->
  <section class="pb-md-5 position-relative">
    <div class="position-relative faculty-hero d-flex">
      <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
        <img class="position-absolute" src="assets/img/contactus-hero.jpg" />
        <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
      </div>
      <div class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
        <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
        <div class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
          <h2 class="heading-font d-inline-block pt-5">
            اتصل بنا
          </h2>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb animate__animated animate__fadeInDown">
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">أكادميه الفنون</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                اتصل بنا
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid floated-contatiner-top position-relative">
    <div class="row">
      <div class="col-lg-11 offset-lg-1 bg-white">
        <div class="col-lg-11 ps-3">
          <section class="pt-4 mt-3 pb-5 row">
            <div class="col-xl-6 col-lg-7 mx-auto">
              <div class="pb-5">
                <h3 class="heading-font fs-1 mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                  ﻣﻘﺮ اﻟﺠﻴﺰه
                </h3>
                <ul class="list-unstyled contact-info animate__animated animate__fadeInUp animate__fast wow">
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-telephone d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>مكالمة هاتفية:</strong>
                    </div>
                    <span class="fs-5 fw-medium ps-1">02 356 139 85</span>
                  </li>
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-location d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>العنوان:</strong>
                    </div>
                    <span class="fw-medium ps-1">ش جمال الدين الأفغانى طريق الهرم – جيزة – مصر</span>
                  </li>
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-email d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>البريد الالكترونى:</strong>
                    </div>
                    <span class="fw-medium ps-1">info@academyofarts.edu.eg</span>
                  </li>
                </ul>
              </div>
              <div class="pb-5 pt-3">
                <h3 class="heading-font fs-1 mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                  ﻣﻘﺮ اﻟﺈﺳﻜﻨﺪرﻳﺔ
                </h3>
                <ul class="list-unstyled contact-info animate__animated animate__fadeInUp animate__fast wow fs-6">
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-telephone d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>مكالمة هاتفية:</strong>
                    </div>
                    <span class="fs-5 fw-medium ps-1">02 356 139 85</span>
                  </li>
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-location d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>العنوان:</strong>
                    </div>
                    <span class="fw-medium ps-1">ش جمال الدين الأفغانى طريق الهرم – جيزة – مصر</span>
                  </li>
                  <li class="mb-3 d-sm-flex align-items-center">
                    <div>
                      <i class="icon-email d-sm-inline-block d-none overflow-hidden text-center"></i>
                      <strong>البريد الالكترونى:</strong>
                    </div>
                    <span class="fw-medium ps-1">info@academyofarts.edu.eg</span>
                  </li>
                </ul>
              </div>
              <!-- <div class="pb-5 pt-3">
                <h3 class="heading-font fs-1 mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                  إطرح سؤال
                </h3>
                <form class="animate__animated animate__fadeInUp animate__fast wow">
                  <div class="form-floating mb-4">
                    <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                    <label for="floatingTextarea">أكتب رساله</label>
                  </div>

                  <div class="row">
                    <div class="col-md">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="floatingInput" placeholder="الاسم الاول">
                        <label for="floatingInput">الاسم الاول</label>
                      </div>
                    </div>
                    <div class="col-md ps-md-0">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="floatingInput" placeholder="اسم العائلة">
                        <label for="floatingInput">اسم العائلة</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-floating mb-4">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">البريد الإلكتروني</label>
                  </div>
                  <button class="btn btn-primary w-100 fw-bold fs-5 py-3 lh-1">
                    إرسال
                  </button>
                </form>

              </div> -->


            </div>

          </section>
        </div>


      </div>
    </div>
  </div>
</main>
