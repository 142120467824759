<app-header></app-header>
<main>
  <!-- whatsapp floating button -->
  <app-whatsapp></app-whatsapp>
  <!-- END whatsapp floating button -->

  <!--HERO Faculty-->
  <section class="pb-md-5 position-relative">
    <div class="position-relative faculty-hero d-flex">
      <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
        <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
        <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
      </div>
      <div class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
        <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
        <div class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
          <h2 class="heading-font d-inline-block pt-5">
            <label class="d-block text-sm-start">1957</label>
            المعهد العالي للسينما
          </h2>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb animate__animated animate__fadeInDown">
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">أكادميه الفنون</a>
              </li>
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">
                  المعهد العالي للسينما
                </a>
              </li>
              <li class="breadcrumb-item mb-2 mb-md-0">
                <a class="text-white">
                  الدراسات الحرة
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                تطور فكر فلسفي
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid floated-contatiner-top position-relative course-details">
    <div class="row">
      <div class="col-lg-11 offset-lg-1 bg-white">
        <div class="col-lg-11 ps-3">
          <div class="pt-4 mt-3 pb-5 row">
            <div class="col-xl-6 col-lg-7 mx-auto">
              <div class="pb-5">
                <h3 class="heading-font fs-2 mb-2 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                  التسجيل في ﺗﻄﻮر ﻓﻜﺮ ﻓﻠﺴﻔﻲ
                </h3>
                <p class="fw-medium opacity-75 animate__animated animate__fadeInUp animate__fast wow mb-4">
                  سوف يتم التواصل عبر البريد الإلكتروني لمراجعة حالة الطلبات
                </p>
                <form class="animate__animated animate__fadeInUp wow">
                  <div class="row">
                    <div class="col-md">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="floatingInput" placeholder="الاسم الاول">
                        <label for="floatingInput">الاسم الاول</label>
                      </div>
                    </div>
                    <div class="col-md ps-md-0">
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="floatingInput" placeholder="اسم العائلة">
                        <label for="floatingInput">اسم العائلة</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-floating mb-4">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">البريد الإلكتروني</label>
                  </div>
                  <div class="form-floating mb-4">
                    <input type="text" class="form-control  text-start" id="floatingInputValue" placeholder="0000000"
                      value="0020">
                    <label for="floatingInputValue">رقم الهاتف</label>
                  </div>
                  <button class="btn btn-primary w-100 fw-bold fs-5 py-3 lh-1">
                    تسجيل
                  </button>
                </form>
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  </div>
  <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
    <div class="row justify-content-center">
      <div class="col-xl-3 col-md-6 mb-md-0 mb-3 pb-md-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
        <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
          <div class="fw-medium flex-fill p-1">
            الأسئلة الشائعة
            <p class="mb-0 text-gray mt-1">إستفسارات شائعة حول الدراسات الحره</p>
          </div>
          <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
        </a>
      </div>
      <div class="col-xl-3 col-md-6 mb-md-0 mb-3 pb-md-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
        <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
          <div class="fw-medium flex-fill p-1">
            موقع الطالب
            <p class="mb-0 text-gray mt-1">
              تعرف على المواد و المهام و النتيجة
            </p>
          </div>
          <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
        </a>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>